import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';
import {
  FullWidthMainContent,
  GridContainer,
} from '../../components/Layout/Layout';
import { theme } from '../../theme/theme';
import { GameContext } from '../../providers/GameProvider/GameProvider';
import { Round, SpellingsContext } from '../../providers/SpellingsProvider/SpellingsProvider';
import { AllowedKeys, Keys } from '../../types/Keys';
import {
  FocusHandler,
  HiddenInput,
  SpellingTile,
  SpellingContainer,
  SentenceContainer,
  Hud,
  ScoreOrb,
  MagicBar,
  MagicBarContainer,
  HealthBarContainer,
  HealthBar,
  SpellingBlank,
  NextRoundButton,
} from './Typer.styled';

import { SpellingTuple } from '../../providers/SpellingsProvider/dictionary';
import { PlayButtonWrapper } from '../Home/Home.styled';
import { Monster, MonsterSprite } from '../../components/Monster/Monster';

function getRandomNotSpeltWord(spellings: SpellingTuple[], spelt: string[]) {
  const notSpelt = spellings.filter((spelling) => !spelt.includes(spelling[0]));
  if (notSpelt) {
    const randomIndex = Math.floor(Math.random() * notSpelt.length);
    return notSpelt[randomIndex];
  }
  const randomSpellingIndex = Math.floor(
    Math.random() * spellings.length
  );
  return spellings[randomSpellingIndex];
}

export function Typer() {
  const { spellings, round, setRound } = useContext(SpellingsContext);
  const [ready, setReady] = useState(false);
  const [sentence, setSentence] = useState('');
  const [spoken, setSpoken] = useState('');

  const { speak } = useSpeechSynthesis();

  const {
    currentMonster,
    hero,
    monster,
    setWord,
    currentSpelling,
    currentWord,
    wrongWords,
    rightWords,
    onUpdate,
    onSubmit,
    onReset,
    onNextRound,
  } = useContext(GameContext);

  useEffect(() => {
    if (spellings?.length && !currentWord) {
      inputRef.current!.value = '';
      const [word, sentence] = getRandomNotSpeltWord(spellings, [
        ...wrongWords,
        ...rightWords,
      ]);
      setWord(word);
      setSentence(sentence);
      setSpoken('');
    }
  }, [spellings, wrongWords, rightWords, setWord, currentWord, speak]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1000);
    inputRef.current!.focus();
  }, [inputRef, setReady]);

  const heroTriumph = hero.health > 0 && monster.health <= 0;
  const monsterTriumph = monster.health > 0 && hero.health <= 0;

  useEffect(() => {
    if (heroTriumph || monsterTriumph) {
      setReady(false);
    }
  }, [heroTriumph, monsterTriumph]);

  useEffect(() => {
    if (!spoken && ready) {
      speak({ text: currentWord });
      setSpoken(currentWord);
    }
  }, [currentWord, spoken, speak, ready]);

  function onContinue() {
    if (!round) return;
    const rounds = Object.values(Round);
    const monsters = Object.values(Monster);
    const currentRound = rounds.indexOf(round);
    const nextRound = currentRound + 1;
    setRound(rounds[nextRound]);
    onNextRound(monsters[nextRound]);
  }

  const liveSentence = useMemo(() => {
    const bits = currentSpelling.split('');
    return sentence.replace(
      currentWord,
      currentWord
        .split('')
        .map((_, i) => bits[i] ?? '?')
        .join('')
    );
  }, [sentence, currentWord, currentSpelling]);

  function setInputFocus() {
    inputRef.current!.focus();
  }

  const paddedSpelling = currentSpelling.padEnd(currentWord.length, '?');

  return (
    <GridContainer height="auto">
      <FocusHandler onClick={setInputFocus} />
      <FullWidthMainContent $backgroundcolor={theme.colors.quinary}>
        <Hud>
          <ScoreOrb $isEnemy={true}>{monster.score}</ScoreOrb>
          <div>
            <div
              style={{
                fontSize: '100px',
                textAlign: 'center',
              }}
            >
              {monster.health > 0 ? (
                <MonsterSprite variant={currentMonster} />
              ) : (
                '☠️'
              )}
            </div>
            <HealthBarContainer>
              <HealthBar $energy={monster.health} />
            </HealthBarContainer>
          </div>
          <MagicBarContainer>
            <MagicBar $energy={monster.magic} />
          </MagicBarContainer>
        </Hud>
        {heroTriumph || monsterTriumph ? (
          <>
            <SentenceContainer>
              {heroTriumph
                ? `Well done you defeated the ${currentMonster}`
                : 'The dark forces have triumphed. Your journey ends here.'}
            </SentenceContainer>
            <SpellingContainer>
              {monsterTriumph &&
                'GAME OVER'
                  .split('')
                  .map((letter, i) =>
                    letter !== ' ' ? (
                      <SpellingTile key={i || letter}>{letter}</SpellingTile>
                    ) : (
                      <SpellingBlank />
                    )
                  )}
            </SpellingContainer>
            {monsterTriumph ? (
              <PlayButtonWrapper>
                <NextRoundButton onClick={onReset}>Play Again</NextRoundButton>
              </PlayButtonWrapper>
            ) : (
              <PlayButtonWrapper>
                <NextRoundButton onClick={onContinue}>Continue</NextRoundButton>
              </PlayButtonWrapper>
            )}
          </>
        ) : (
          <>
            <SentenceContainer>{liveSentence}</SentenceContainer>
            <SpellingContainer>
              {paddedSpelling.split('').map((letter, i) => (
                <SpellingTile key={i || letter}>{letter}</SpellingTile>
              ))}
            </SpellingContainer>
          </>
        )}
        <HiddenInput
          type="text"
          ref={inputRef}
          maxLength={currentWord.length}
          onChange={(e) => onUpdate(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === Keys.ENTER) {
              onSubmit();
            }
            if (e.key === Keys.SPACE) {
              e.preventDefault();
              speak({ text: currentWord });
            }
            if (!Object.values(AllowedKeys).includes(e.key as AllowedKeys)) {
              e.preventDefault();
            }
          }}
        />
        <Hud>
          <ScoreOrb>{hero.score}</ScoreOrb>
          <div>
            <div
              style={{
                fontSize: '100px',
                textAlign: 'center',
              }}
            >
              {hero.health > 0 ? '🧙‍♀️' : '☠️'}
            </div>
            <HealthBarContainer>
              <HealthBar $energy={hero.health} />
            </HealthBarContainer>
          </div>
          <MagicBarContainer>
            <MagicBar $energy={hero.magic} />
          </MagicBarContainer>
        </Hud>
      </FullWidthMainContent>
    </GridContainer>
  );
}
