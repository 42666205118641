import { createContext, useEffect, useMemo, useState } from 'react';
import {
  SpellingTuple,
  ks1Spellings,
  ks2Spellings,
  ks2SpellingsAdvanced,
} from './dictionary';

type SpellingsProviderProps = {
  children: React.ReactNode;
};

type SpellingsContextType = {
  spellings: SpellingTuple[];
  level: Level | null;
  round: Round | null;
  setLevel: (level: Level) => void;
  setRound: (round: Round) => void;
};

export enum Level {
  Beginner = 'Year 1 & 2 KS1',
  Intermediate = 'Year 3 & 4 KS2',
  Advanced = 'Year 5 & 6 KS2',
}

export enum Round {
  One = 'Round One',
  Two = 'Round Two',
  Three = 'Round Three',
  Four = 'Round Four',
  Five = 'Round Five',
}

export const SpellingsContext = createContext<SpellingsContextType>({
  spellings: [],
  level: null,
  round: null,
  setLevel: () => {},
  setRound: () => {},
});

function SpellingsProvider({ children }: SpellingsProviderProps) {
  const levelInStorage = localStorage.getItem('level');
  const roundInStorage = localStorage.getItem('round');
  const [level, setLevel] = useState<Level | null>(
    (levelInStorage as Level) || Level.Beginner
  );
  const [round, setRound] = useState<Round | null>(
    (roundInStorage as Round) || Round.One
  );
  const [spellings, setSpellings] = useState<SpellingTuple[]>([]);
  const [roundSpellings, setRoundSpellings] = useState<SpellingTuple[]>([]);

  useEffect(() => {
    switch (level) {
      case Level.Beginner:
        setSpellings(ks1Spellings);
        break;
      case Level.Intermediate:
        setSpellings(ks2Spellings);
        break;
      case Level.Advanced:
        setSpellings(ks2SpellingsAdvanced);
        break;
      default:
        setSpellings([]);
        break;
    }
    localStorage.setItem('level', level as string);
  }, [level]);

  const spellingGroups = useMemo(() => {
    return spellings.reduce((acc, word, i) => {
      const r = i % 5;
      if (!acc[r]) acc[r] = [];
      acc[r].push(word);
      return acc;
    }, [] as SpellingTuple[][]);
  }, [spellings]);

  useEffect(() => {
    switch (round) {
      case Round.One:
        setRoundSpellings(spellingGroups[0]);
        break;
      case Round.Two:
        setRoundSpellings(spellingGroups[1]);
        break;
      case Round.Three:
        setRoundSpellings(spellingGroups[2]);
        break;
      case Round.Four:
        setRoundSpellings(spellingGroups[3]);
        break;
      case Round.Five:
        setRoundSpellings(spellingGroups[4]);
        break;
    }
  }, [round, spellingGroups]);

  useEffect(() => {
    localStorage.setItem('round', round as string);
  }, [round])

  const value = useMemo(
    () => ({ spellings: roundSpellings, level, setLevel, round, setRound }),
    [roundSpellings, level, round]
  );

  return (
    <SpellingsContext.Provider value={value}>
      {children}
    </SpellingsContext.Provider>
  );
}

export default SpellingsProvider;
