export enum Monster {
  Goblin = 'Goblin',
  Troll = 'Troll',
  Orc = 'Orc',
  Witch = 'Witch',
  Dragon = 'Dragon',
}

const MonsterMap = {
  [Monster.Goblin]: '🧟‍♀️',
  [Monster.Troll]: '👹',
  [Monster.Orc]: '🧌',
  [Monster.Dragon]: '🐉',
  [Monster.Witch]: '🧙🏼',
};

interface MonsterProps {
  variant: Monster;
}

export function MonsterSprite({ variant }: MonsterProps) {
  return <>{MonsterMap[variant]}</>;
}
