import styled from 'styled-components';

export const LevelSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const LevelButton = styled.button`
  border: none;
  border-radius: 8px;
  width: 100px;
  height: 100px;
  font-size: 0px;
  background-color: ${({ theme }) => theme.colors.quinary};

  &:before {
    font-size: ${({ theme }) => theme.fonts.sizes.xl};
    color: ${({ theme }) => theme.fonts.ink.dark};
  }

  &::disabled {
    opacity: 0.5;
  }
`;

export const PreviousLevelButton = styled(LevelButton)`
  margin-right: 1rem;
  &:before {
    content: '◀';
  }
`;

export const NextLevelButton = styled(LevelButton)`
  margin-left: 1rem;
  &:before {
    content: '▶';
  }
`;

export const LevelSelectSelection = styled.div`
  height: 100px;
  min-width: 250px;
  padding: 0 16px;
  border-radius: 8px;
  line-height: 100px;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.sizes.xl};
  background-color: ${({ theme }) => theme.fonts.paper.light};
  color: ${({ theme }) => theme.fonts.ink.dark};
`;
