import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Intro = styled.div`
  text-align: justify;
  margin-bottom: 32px;
  font-size: ${({ theme }) => theme.fonts.sizes.large};
`;

export const PlayButtonWrapper = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
`;

export const PlayLink = styled(Link)`
  border: none;
  border-radius: 8px;
  width: 280px;
  height: 100px;
  text-align: center;
  text-decoration: none;
  line-height: 100px;
  font-size: ${({ theme }) => theme.fonts.sizes.xl};
  background-color: ${({ theme }) => theme.fonts.ink.dark};
  color: ${({ theme }) => theme.fonts.ink.light};
  cursor: pointer;
`;