export type SpellingTuple = [string, string];

export const ks1Spellings: SpellingTuple[] = [
  ['push', 'I gave the door a gentle push to open it.'],
  ['gold', 'I found gold buried in the sand.'],
  ['great', 'She had a great time at the party.'],
  ['path', 'The path through the forest was overgrown.'],
  ['the', 'the quick brown fox jumps over the lazy dog.'],
  ['come', 'come with me, I have something to show you.'],
  ['go', 'I want to go to the park tomorrow.'],
  ['mind', 'Clear your mind and focus on the task.'],
  ['clothes', 'I need to buy new clothes for the trip.'],
  ['past', 'The past few days have been challenging.'],
  ['sugar', 'I prefer my coffee without sugar.'],
  ['some', 'Can you pass me ____ of those cookies?'],
  ['so', 'The fairground was so much fun!'],
  ['floor', 'The floor was very clean.'],
  ['cold', 'I caught a cold after getting wet in the rain.'],
  ['could', 'If I tried, I could solve this puzzle.'],
  ['a', 'I saw a beautiful butterfly in the garden.'],
  ['father', 'My father taught me how to ride a bike.'],
  ['class', 'I have class tomorrow at 9 AM.'],
  ['would', 'If I had a million dollars, I would buy a mansion.'],
  ['do', 'Please do your homework before dinner.'],
  ['one', 'I found one dollar on the street.'],
  ['by', 'I arrived by bus and met you at the station.'],
  ['because', "I'm happy because I passed the exam."],
  ['to', 'I want to go to the movies tonight.'],
  ['once', 'once upon a time...'],
  ['my', 'This is my favorite book.'],
  ['kind', 'She is kind and always helps others.'],
  ['hold', 'Please hold my hand as we cross the street.'],
  ['water', "I'm thirsty; can you get me a glass of water?"],
  ['sure', 'Are you sure you want to do this?'],
  ['ask', "Don't be afraid to ask for help when needed."],
  ['here', "I'll be here waiting for your arrival."],
  ['behind', 'The treasure is hidden behind the old tree.'],
  ['told', 'She told me a funny story yesterday.'],
  ['again', "Let's meet again at the same place next week."],
  ['eye', 'He has a sharp eye for detail.'],
  ['today', "I'm feeling great today; the sun is shining."],
  ['of', 'This cup is made of fine china.'],
  ['friend', 'A true friend is always there for you.'],
  ['there', 'Is there anything interesting happening?'],
  ['whole', 'The whole family is going on vacation.'],
  ['every', 'I exercise every morning to stay healthy.'],
  ['grass', 'The grass is always greener on the other side.'],
  ['should', 'You should visit the doctor for a check-up.'],
  ['school', 'My kids go to the same school I attended.'],
  ['where', 'Do you know where I left my keys?'],
  ['any', 'Is there any coffee left in the pot?'],
  ['said', 'He said he would come to the party.'],
  ['pass', 'I hope I pass the exam with flying colors.'],
  ['who', 'who is responsible for this mess?'],
  ['love', 'I love spending time with my family.'],
  ['break', "Don't break the glass; it's fragile."],
  ['put', 'Please put the dishes in the sink.'],
  ['child', 'Every child deserves a loving home.'],
  ['plant', "Let's plant these flowers in the garden."],
  ['says', "She says that it's going to rain today."],
  ['are', 'The are keys are on the kitchen counter.'],
  ['steak', "I'll have a medium-rare steak for dinner."],
  ['your', 'Is this your first time visiting the city?'],
  ['wild', 'We spotted a wild deer in the woods.'],
  ['were', 'They were late to the party last night.'],
  ['pull', 'Can you pull the rope to move the car?'],
  ['bath', 'I need a relaxing bath after a long day.'],
  ['most', "She's the most talented musician I know."],
  ['busy', "I'm always busy at work during the weekdays."],
  ['they', 'they were excited to see the new movie.'],
  ['was', 'I heard it was a beautiful wedding.'],
  ['hour', "It's been an hour since I started working."],
  ['people', 'people from all over the world attended the event.'],
  ['be', 'I want to be a doctor when I grow up.'],
  ['full', 'The jar is full of delicious homemade jam.'],
  ['both', "They're both excited about their upcoming vacation."],
  ['is', 'The cake is ready for the birthday party.'],
  ['pretty', 'She looks pretty in that new dress.'],
  ['move', 'Can you help me move this heavy piece of furniture?'],
  ['he', 'he is a talented musician and plays multiple instruments.'],
  ['house', 'Their house is surrounded by beautiful gardens.'],
  ['children', 'The playground was filled with laughing children.'],
  ['beautiful', 'The sunset over the ocean was simply beautiful.'],
  ['me', 'She gave me a thoughtful birthday gift.'],
  ['his', 'his decision-making skills are impressive.'],
  ['our', "We're going on vacation with our friends."],
  [
    'climb',
    "It's a challenging hike, but the view at the top is worth the climb.",
  ],
  ['prove', 'He aims to prove his worth through hard work.'],
  ['has', 'She has a lovely singing voice.'],
  ['door', 'Please close the door behind you.'],
  ['after', 'after the rain stopped, we went for a walk.'],
  ['half', "I'll take half of your sandwich if you don't mind."],
  ['she', 'she was surprised by the unexpected gift.'],
  ['only', 'The only reason I agreed was to help a friend.'],
  ['wet', 'I got wet in the rain and had to change clothes.'],
  ['poor', 'The poor kitten was shivering in the cold.'],
  ['old', 'The old man had silver hair.'],
  ['fast', 'The fast train left the station in a hurry.'],
  ['money', 'He counted the money he had saved for months.'],
  ['find', 'Can you help me find my lost keys?'],
  ['many', 'There are many books on the library shelves.'],
  ['last', 'This is your last chance to make it right.'],
  ['improve', "Let's work together to improve our community."],
  ['no', "There's no need to worry; everything will be fine."],
  ['you', 'I believe in you and your abilities.'],
  ['mr ', 'mr Smith is our neighbor and a great guy.'],
  ['mrs', 'mrs Johnson is a wonderful teacher.'],
  ['parents', 'Our parents always support our dreams.'],
  ['christmas', 'christmas is my favorite holiday; I love the festivities.'],
  ['everybody', 'everybody is invited to the party, so bring your friends.'],
  ['even', "It's a simple task; even a child can do it."],
];

export const ks2Spellings: SpellingTuple[] = [
  ['accident', 'The accident happened unexpectedly.'],
  ['accidentally', 'I accidentally spilled my drink.'],
  ['actual', 'This is the actual size of the object.'],
  ['actually', 'I actually enjoyed the movie.'],
  ['address', 'Please write your address clearly.'],
  ['answer', 'Can you provide the correct answer?'],
  ['appear', 'He will appear at the party later.'],
  ['arrive', "I'll arrive at the airport at 3 PM."],
  ['believe', 'I believe in your abilities.'],
  ['bicycle', 'She rode her bicycle to work today.'],
  ['breath', 'Take a deep breath and relax.'],
  ['breathe', "Don't forget to breathe while swimming."],
  ['build', 'They will build a new house next year.'],
  ['busy', "I'm always busy during the weekdays."],
  ['business', 'She runs a successful business.'],
  ['calendar', 'Mark the date on your calendar.'],
  ['caught', 'She caught the ball with one hand.'],
  ['centre', 'The shopping mall is at the centre of the city.'],
  ['century', 'The 20th century had many technological advances.'],
  ['certain', "I'm certain that it will be a great event."],
  ['circle', 'Draw a perfect circle on the paper.'],
  ['complete', 'Please complete the form and submit it.'],
  ['consider', 'You should consider all the options.'],
  ['continue', "Let's continue working on this project."],
  ['decide', "It's time to decide which path to take."],
  ['describe', 'Can you describe the scene in more detail?'],
  ['different', 'People have different opinions about this.'],
  ['difficult', 'Learning a new language can be difficult.'],
  ['disappear', 'The sun will disappear below the horizon.'],
  ['early', 'I like to wake up early in the morning.'],
  ['earth', 'We live on planet earth.'],
  ['eight', 'There are eight planets in our solar system.'],
  ['eighth', 'She came in eighth place in the race.'],
  ['enough', 'We have enough food for everyone.'],
  ['exercise', 'Regular exercise is good for your health.'],
  ['experience', 'I had a unique experience during my travels.'],
  ['experiment', 'The science experiment was a success.'],
  ['extreme', 'The weather conditions were extreme.'],
  ['famous', "She's a famous actress in Hollywood."],
  ['favourite', 'Chocolate is my favourite dessert.'],
  ['february', 'My birthday is in february.'],
  ['forward', "Keep moving forward; don't stop."],
  ['forwards', 'She walked forwards towards her goal.'],
  ['fruit', 'I enjoy eating fresh fruit every morning.'],
  ['grammar', 'Proper grammar is essential for writing.'],
  ['group', 'Join the study group to prepare for the exam.'],
  ['guard', 'The security guard is responsible for safety.'],
  ['guide', 'The tour guide showed us around the city.'],
  ['heard', 'I heard a beautiful song on the radio.'],
  ['heart', 'The heart is a vital organ in the body.'],
  ['height', 'The height of the mountain is impressive.'],
  ['history', 'Learn about the history of our civilization.'],
  ['imagine', 'Can you imagine a world without borders?'],
  ['increase', 'There was a significant increase in sales.'],
  ['important', "It's important to make a good first impression."],
  ['interest', 'Her interest lies in astronomy.'],
  ['island', 'They visited a remote island in the Pacific.'],
  ['knowledge', 'Education broadens your knowledge.'],
  ['learn', 'We learn from our experiences.'],
  ['length', 'Measure the length of the room for furniture.'],
  ['library', 'I spent hours at the local library reading.'],
  ['material', 'The material used in this project is high quality.'],
  ['medicine', 'He studied medicine to become a doctor.'],
  ['mention', "Don't forget to mention your achievements."],
  ['minute', "I'll be there in a minute."],
  ['natural', 'The natural beauty of the park is breathtaking.'],
  ['naughty', 'The naughty child played pranks on his siblings.'],
  ['notice', 'Did you notice the changes in the garden?'],
  ['occasion', 'Dress up for the special occasion.'],
  ['occasionally', 'I occasionally treat myself to dessert.'],
  ['often', 'I often go for a run in the morning.'],
  ['opposite', 'The restaurant is located opposite the park.'],
  ['ordinary', 'It was just an ordinary day.'],
  ['particular', 'She has a particular fondness for art.'],
  ['peculiar', 'There was something peculiar about that place.'],
  ['perhaps', 'I will join you for lunch, perhaps.'],
  ['popular', 'The restaurant is always popular with customers.'],
  ['position', 'She got a promotion to a higher position.'],
  ['possess', 'He is said to possess rare art pieces.'],
  ['possession', 'The car is his most valuable possession.'],
  ['possible', 'With hard work, anything is possible.'],
  ['potatoes', 'Mashed potatoes are a classic side dish.'],
  ['pressure', 'She can handle high pressure situations.'],
  ['probably', "I'll probably come to your party."],
  ['promise', "Keep your promise and you'll succeed."],
  ['purpose', "What's the purpose of this meeting?"],
  ['quarter', "I'll be back in a quarter of an hour."],
  ['question', 'Please ask any question you have.'],
  ['recent', 'I watched a recent documentary last night.'],
  ['regular', 'She follows a regular exercise routine.'],
  ['reign', "The king's reign was marked by prosperity."],
  ['remember', 'I can remember the events clearly.'],
  ['sentence', 'Write a meaningful sentence for your essay.'],
  ['separate', 'They live in separate houses but close by.'],
  ['special', 'This is a special occasion for us.'],
  ['straight', 'Walk in a straight line to the end.'],
  ['strange', 'There was something strange about that place.'],
  ['strength', 'Her strength is admirable.'],
  ['suppose', "I suppose we'll meet again soon."],
  ['surprise', 'The birthday surprise was a success.'],
  ['therefore', "He's sick; therefore he can't come today."],
  ['though', "It's a tough challenge, but he'll succeed though."],
  ['although', 'although it rained, the picnic was fun.'],
  ['thought', 'What thought are you lost in right now?'],
  ['through', 'I saw it through the window.'],
  ['various', 'She collected various shells on the beach.'],
  ['weight', 'The weight of the package is 10 pounds.'],
  ['woman', "She's a remarkable woman in her field."],
  ['women', 'Many women have achieved great success.'],
];

export const ks2SpellingsAdvanced: SpellingTuple[] = [
  ['accommodate', 'The hotel can accommodate many guests.'],
  ['accompany', "I'd like you to accompany me to the party."],
  ['according', 'The plan will proceed according to schedule.'],
  ['achieve', 'With hard work, you can achieve your goals.'],
  ['aggressive', 'He has a very aggressive personality.'],
  ['amateur', "He's an amateur photographer, but his work is great."],
  ['ancient', 'The ruins are ancient and full of history.'],
  ['apparent', 'The problem is apparent and needs a solution.'],
  ['appreciate', 'I appreciate your help in this matter.'],
  ['attached', 'The document is attached for your reference.'],
  ['available', 'Is the book available in the library?'],
  ['average', 'The average rainfall in this area is 40 inches.'],
  ['awkward', 'He felt awkward in the social gathering.'],
  ['bargain', 'I got a great bargain on this purchase.'],
  ['bruise', 'She got a nasty bruise from the fall.'],
  ['category', 'Please choose the correct category for your entry.'],
  ['cemetery', 'The cemetery is a peaceful place for reflection.'],
  ['committee', 'The committee will discuss the new proposal.'],
  ['communicate', "It's important to communicate clearly in a team."],
  ['community', 'Our community comes together for local events.'],
  ['competition', 'The competition is fierce in this industry.'],
  ['conscience', 'His conscience guided him to make the right choice.'],
  ['conscious', 'She is conscious of her actions and their impact.'],
  ['controversy', 'The controversy around the topic is ongoing.'],
  ['convenience', 'The convenience store is open 24/7.'],
  ['correspond', 'I correspond regularly with my pen pal.'],
  ['critic', 'The critic had mixed reviews of the movie.'],
  ['criticise', "It's not right to criticise others unfairly."],
  ['curiosity', 'Her curiosity led her to explore new places.'],
  ['definite', 'She has a definite plan for her future.'],
  ['desperate', 'The situation is desperate and needs attention.'],
  ['determined', 'He is determined to achieve his goals.'],
  ['develop', 'The company will develop new software soon.'],
  ['dictionary', 'A dictionary is a great tool for vocabulary.'],
  ['disastrous', 'The results of the experiment were disastrous.'],
  ['embarrass', "It's easy to embarrass someone by mistake."],
  ['environment', 'We must protect the environment for the future.'],
  ['equip', 'We need to equip the team with better gear.'],
  ['equipped', 'The laboratory is fully equipped for experiments.'],
  ['equipment', 'The equipment needed for the project is ready.'],
  ['especially', 'I especially enjoyed the concert last night.'],
  ['exaggerate', "Don't exaggerate; just tell the truth."],
  ['excellent', 'Your work is excellent and highly praised.'],
  ['existence', 'The existence of life on other planets is debated.'],
  ['explanation', 'He gave a detailed explanation of the concept.'],
  ['familiar', 'The place felt familiar even though it was new.'],
  ['foreign', 'She speaks two foreign languages fluently.'],
  ['forty', "He's forty years old and still active."],
  ['frequently', 'I frequently visit my family on weekends.'],
  ['government', 'The government is responsible for policy decisions.'],
  ['guarantee', 'The product comes with a money-back guarantee.'],
  ['harass', "It's important not to harass anyone at work."],
  ['hindrance', 'The lack of funds is a hindrance to progress.'],
  ['identity', 'Your identity is what makes you unique.'],
  ['immediate', 'We need an immediate solution to this problem.'],
  ['immediately', 'Please call me back immediately.'],
  ['individual', 'Each individual has unique strengths.'],
  ['interfere', "Don't interfere with their personal matters."],
  ['interrupt', "Please don't interrupt me while I'm speaking."],
  ['language', 'Learning a new language can be challenging.'],
  ['leisure', 'He enjoys his leisure time playing the guitar.'],
  ['lightning', 'The lightning strike was bright and loud.'],
  ['marvellous', 'The view from the mountaintop was marvellous.'],
  ['mischievous', 'The mischievous child played pranks on his siblings.'],
  ['muscle', 'He has well-defined muscle from regular exercise.'],
  ['necessary', 'Education is a necessary foundation for success.'],
  ['neighbour', 'Our neighbour is friendly and helpful.'],
  ['nuisance', 'The noisy construction is a nuisance for residents.'],
  ['occupy', 'The children occupy themselves with games.'],
  ['occur', 'The event will occur on the weekend.'],
  ['opportunity', "Don't miss this opportunity for growth."],
  ['parliament', 'The parliament convenes to pass laws.'],
  ['persuade', 'She can persuade others with her arguments.'],
  ['physical', 'Regular physical activity is important for health.'],
  ['prejudice', 'We should overcome prejudice and treat all equally.'],
  ['privilege', "It's a privilege to have access to education."],
  ['profession', 'She chose a profession in medicine to help others.'],
  ['programme', 'The programme includes various activities.'],
  ['pronunciation', 'Can you work on your pronunciation of this word?'],
  ['queue', "There's a long queue at the ticket counter."],
  ['recognise', 'I recognise her from the photograph.'],
  ['recommend', 'I recommend this book to all readers.'],
  ['relevant', 'The information is relevant to the topic.'],
  ['restaurant', "Let's dine at the new restaurant downtown."],
  ['rhyme', 'Write a poem with a rhyme scheme.'],
  ['rhythm', 'The rhythm of the music was captivating.'],
  ['sacrifice', 'He made a sacrifice for the greater good.'],
  ['secretary', 'The secretary is responsible for office tasks.'],
  ['shoulder', 'She carried the heavy bag on her shoulder.'],
  ['signature', 'Please sign with your legal signature.'],
  ['sincerely', 'I sincerely hope you feel better soon.'],
  ['sincere', 'Her sincere apology was appreciated.'],
  ['soldier', 'The soldier served in the army for years.'],
  ['stomach', 'I have a pain in my stomach after eating.'],
  ['sufficient', 'We have sufficient supplies for the trip.'],
  ['suggest', 'Can you suggest a solution to the problem?'],
  ['symbol', 'The dove is a symbol of peace.'],
  ['system', 'The system is designed for efficiency.'],
  ['temperature', 'The temperature dropped below freezing.'],
  ['thorough', 'A thorough investigation was conducted.'],
  ['twelfth', 'She finished in twelfth place in the race.'],
  ['variety', 'The store offers a variety of products.'],
  ['vegetable', 'I always include a vegetable in my meals.'],
  ['vehicle', 'The electric vehicle is energy-efficient.'],
  ['yacht', 'They enjoyed a luxurious cruise on a yacht.'],
];
