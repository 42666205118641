import React, { createContext, useMemo, useReducer } from 'react';
import { gameReducer, gameInitialState, GameActionType, Entity } from './gameReducer';
import { Monster } from '../../components/Monster/Monster';

interface GameProviderProps {
  children: React.ReactNode;
}

interface GameContextType {
  rightWords: string[];
  wrongWords: string[];
  currentMonster: Monster;
  hero: Entity;
  monster: Entity;
  currentSpelling: string;
  currentWord: string;
  setWord: (word: string) => void;
  onSubmit: () => void;
  onUpdate: (spelling: string) => void;
  onReset: () => void;
  onNextRound: (monster: Monster) => void;
}

export const GameContext = createContext<GameContextType>({
  rightWords: [],
  wrongWords: [],
  currentSpelling: '',
  currentWord: '',
  currentMonster: Monster.Troll,
  hero: {
    health: 100,
    magic: 0,
    score: 0,
  },
  monster: {
    health: 100,
    magic: 0,
    score: 0,
  },
  setWord: () => {},
  onSubmit: () => {},
  onUpdate: () => {},
  onReset: () => {},
  onNextRound: () => {},
});

export function GameProvider({ children }: GameProviderProps) {
  const [state, dispatch] = useReducer(gameReducer, gameInitialState);

  const { 
    rightWords,
    wrongWords,
    currentSpelling,
    currentWord,
    currentMonster,
    hero,
    monster,
  } = state;

  const value = useMemo(() => {
    return {
      rightWords,
      wrongWords,
      currentSpelling,
      currentWord,
      currentMonster,
      hero,
      monster,
      setWord: (word: string) => {
        dispatch({ type: GameActionType.SET_CURRENT_WORD, payload: word });
      },
      onUpdate: (spelling: string) => {
        dispatch({ type: GameActionType.UPDATE_SPELLING, payload: spelling });
      },
      onSubmit: () => {
        dispatch({ type: GameActionType.SUBMIT });
      },
      onReset: () => {
        dispatch({ type: GameActionType.RESET });
      },
      onNextRound: (monster: Monster) => {
        dispatch({ type: GameActionType.SET_MONSTER, payload: monster });
      },
    };
  }, [
    rightWords,
    wrongWords,
    currentSpelling,
    currentWord,
    currentMonster,
    hero,
    monster,
  ]);

  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  );
}