import styled from 'styled-components';
import { Header } from '../../components/Layout/Layout';

interface GameItemProps {
  $isEnemy?: boolean;
}

interface EnergyBarProps {
  $energy: number;
}

export const GameHeader = styled(Header)`
  z-index: 1;
  display: flex;
  justify-content: space-between;
`;

export const FocusHandler = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const SpellingContainer = styled.div`
  margin: 10px 0;
  text-align: center;
`;

export const SentenceContainer = styled.div`
  margin: 32px 0;
  height: 20px;
  text-align: center;
  font-size: ${(props) => props.theme.fonts.sizes.large};
  line-height: ${(props) => props.theme.fonts.sizes.large};
`;

export const SpellingTile = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.quinary};
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  margin-right: 10px;
`;

export const SpellingBlank = styled(SpellingTile)`
  background-color: none;
  border: none;
`;

export const HiddenInput = styled.input`
  position: fixed;
  opacity: 0;
`;

export const Hud = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;

export const ScoreOrb = styled.div<GameItemProps>`
  margin: 0 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$isEnemy
      ? props.theme.colors.secondary
      : props.theme.colors.quaternary};
  color: ${(props) => props.theme.fonts.ink.light};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
`;

const maxMagic = 100;
export const MagicBarContainer = styled.div`
  posittion: relative;
  margin: 0 10px;
  width: 20px;
  height: ${maxMagic}px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primary};
  position: relative;
`;

export const MagicBar = styled.div<EnergyBarProps & GameItemProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: ${(props) => (props.$energy / maxMagic) * 100}px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.$isEnemy
      ? props.theme.colors.secondary
      : props.theme.colors.quaternary};
`;

const maxHealth = 100;
export const HealthBarContainer = styled.div`
  margin: 10px 0;
  width: ${maxHealth}px;
  height: 20px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primary};
  position: relative;
`;

export const HealthBar = styled.div<EnergyBarProps & GameItemProps>`
  width: ${(props) => (props.$energy / maxHealth) * 100}px;
  height: 20px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.secondary};
  position: relative;
`;

export const NextRoundButton = styled.button`
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.quaternary};
  color: ${(props) => props.theme.fonts.ink.light};
  font-size: ${(props) => props.theme.fonts.sizes.medium};
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  border: none;
  cursor: pointer;
  z-index: 1000;
`;
