import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import {
  Footer,
  GridContainer,
  Header,
  LeftColumn,
  MainContent,
  RightColumn,
} from './components/Layout/Layout';
import { Typer } from './views/Typer/Typer';
import SpellingsProvider from './providers/SpellingsProvider/SpellingsProvider';
import { GameProvider } from './providers/GameProvider/GameProvider';
import { Words } from './components/Words/Words';
import { Home } from './views/Home/Home';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SpellingsProvider>
        <GameProvider>
          <GridContainer height="100vh">
            <Header>SpellTyper</Header>
            <LeftColumn></LeftColumn>
            <MainContent>
              <BrowserRouter>
              <Routes>
                <Route path="/play" Component={Typer} />
                <Route path="*" Component={Home} />
                </Routes>
              </BrowserRouter>
            </MainContent>
            <RightColumn>
              <Words />
            </RightColumn>
            <Footer>© Copyright SpellTyper {new Date().getFullYear()}</Footer>
          </GridContainer>
        </GameProvider>
      </SpellingsProvider>
    </ThemeProvider>
  );
}

export default App;
