import React, { useContext } from 'react';
import { GameContext } from '../../providers/GameProvider/GameProvider';

export function Words() {
  const {
    wrongWords,
  } = useContext(GameContext);

  return !wrongWords.length ? null : (
    <div>
      <h2>Spelling List</h2>
      <ul>
        {wrongWords.map((word, index) => <li key={index}>{word}</li>)}
      </ul>
      <button>Practice</button>
    </div>
  );
}