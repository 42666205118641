import React, { useContext, useEffect, useState } from 'react';
import { LevelSelectSelection, LevelSelectWrapper, NextLevelButton, PreviousLevelButton } from './LevelSelect.styled';
import { Level, SpellingsContext } from '../../providers/SpellingsProvider/SpellingsProvider';

export function LevelSelect() {
  const { level, setLevel } = useContext(SpellingsContext);
  const options = Object.values(Level);

  const selectedLevelIndex = options.findIndex((option) => option === level);
  const [selectedLevel, setSelectedLevel] = useState(Math.max(selectedLevelIndex, 0));

  useEffect(() => {
    setLevel(options[selectedLevel]);
  }, [level, setLevel, selectedLevel, options]);

  return (
    <LevelSelectWrapper>
      <PreviousLevelButton
        onClick={() => setSelectedLevel(Math.max(selectedLevel - 1, 0))}
        disabled={level === Level.Beginner}
      >
        Previous
      </PreviousLevelButton>
      <LevelSelectSelection>{level}</LevelSelectSelection>
      <NextLevelButton
        onClick={() =>
          setSelectedLevel(Math.min(selectedLevel + 1, options.length - 1))
        }
        disabled={level === Level.Advanced}
      >
        Next
      </NextLevelButton>
    </LevelSelectWrapper>
  );
}