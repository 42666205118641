import React, { useEffect } from 'react';
import {
  FullWidthMainContent,
  GridContainer,
} from '../../components/Layout/Layout';
import { Intro, PlayLink, PlayButtonWrapper } from './Home.styled';
import { LevelSelect } from '../../components/LevelSelect/LevelSelect';

export function Home() {
  useEffect(() => {
    console.log('Home mounted');
    localStorage.setItem('round', '');
  });

  return (
    <GridContainer>
      <FullWidthMainContent>
        <Intro>
          <p>
            Hark, young scribes and budding wizards! A thrilling adventure
            awaits in a realm filled with enchanting creatures. You are a wizard
            on a magical quest to defeat dragons, ogres, and other fantastical
            beasts. But fear not, for your keyboard is your wand, and your words
            are your spells.
          </p>
          <p>
            The ancient scrolls will present you with sentences, and one word
            shall be hidden. You have two chances to guess the missing word, or
            your energy will fade. But with each correct guess, your magical
            power will grow, a spell shall be cast, and your enemy's energy will
            wane.
          </p>
          <p>
            This is a magical journey filled with fun, where your typing skills
            will be your guide.
          </p>
          <p>
            Get ready, young wizards, and let your fingers be as swift as the
            wind in this epic adventure through the pages of ancient legends!
          </p>
        </Intro>
        <LevelSelect />
        <PlayButtonWrapper>
          <PlayLink to="/play">Play</PlayLink>
        </PlayButtonWrapper>
      </FullWidthMainContent>
    </GridContainer>
  );
}
