import { Monster } from '../../components/Monster/Monster';

export enum GameActionType {
  SET_CURRENT_WORD = 'SET_CURRENT_WORD',
  SET_MONSTER = 'SET_MONSTER',
  UPDATE_SPELLING = 'UPDATE_SPELLING',
  SUBMIT = 'SUBMIT',
  RESET = 'RESET',
}

type GameAction = 
  { type: GameActionType.SET_CURRENT_WORD; payload: string } |
  { type: GameActionType.SET_MONSTER; payload: Monster } |
  { type: GameActionType.UPDATE_SPELLING; payload: string } |
  { type: GameActionType.SUBMIT } |
  { type: GameActionType.RESET };

export interface Entity {
  health: number;
  magic: number;
  score: number;
}

type GameState = {
  rightWords: string[];
  wrongWords: string[];
  currentWord: string;
  currentSpelling: string;
  currentMonster: Monster;
  hero: Entity;
  monster: Entity;
};

const entityDefaults = {
  health: 100,
  magic: 0,
  score: 0,
};

export const gameInitialState = {
  rightWords: [],
  wrongWords: [],
  currentWord: '',
  currentSpelling: '',
  currentMonster: Monster.Goblin,
  hero: {
    ...entityDefaults,
  },
  monster: {
    ...entityDefaults,
  }
};

function getIncorrectLetters({currentWord, currentSpelling}: GameState) {
  const incorrectLetters = currentSpelling.split('').filter((letter, index) => {
    return letter !== currentWord[index];
  });
  return incorrectLetters.length;
}

function calculateEntityHealth(state: GameState): GameState {
  // if magic >= 100 then opposite entity health - 10
  const {hero, monster} = state;
  if (hero.magic >= 100) {
    return {
      ...state,
      hero: {
        ...hero,
        magic: 0,
      },
      monster: {
        ...monster,
        health: monster.health - 25,
      },
    };
  }
  if (monster.magic >= 100) {
    return {
      ...state,
      hero: {
        ...hero,
        health: hero.health - 25,
      },
      monster: {
        ...monster,
        magic: 0,
      },
    };
  }
  return state;
}

export function gameReducer(
  state: GameState,
  action: GameAction
): GameState {
  switch (action.type) {
    case GameActionType.RESET:
      return gameInitialState;
    case GameActionType.SET_MONSTER:
      return {
        ...gameInitialState,
        wrongWords: state.wrongWords,
        rightWords: state.rightWords,
        currentMonster: action.payload,
      }
    case GameActionType.SET_CURRENT_WORD:
      return {
        ...state,
        currentWord: action.payload,
      };
    case GameActionType.UPDATE_SPELLING:
      return {
        ...state,
        currentSpelling: action.payload,
      };
    case GameActionType.SUBMIT:
      let newState;
      if (state.currentWord.toLowerCase() === state.currentSpelling.toLocaleLowerCase()) {
        newState = {
          ...state,
          rightWords: [...state.rightWords, state.currentWord],
          currentSpelling: '',
          currentWord: '',
          hero: {
            ...state.hero,
            score: state.hero.score + 1,
            magic: state.hero.magic + 20,
          },
        };
      } else {
        newState = {
          ...state,
          wrongWords: [...state.wrongWords, state.currentWord],
          currentSpelling: '',
          currentWord: '',
          monster: {
            ...state.monster,
            score: state.monster.score + 1,
            magic: state.monster.magic + 20,
          },
        };
      }
      return calculateEntityHealth(newState);
    default:
      return state;
  }
};