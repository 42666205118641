import { DefaultTheme } from 'styled-components';
import { Theme } from './types';

const theme: DefaultTheme & Theme = {
  colors: {
    primary: '#423E37',
    secondary: '#C33149',
    tertiary: '#B7D5D4',
    quaternary: '#058C42',
    quinary: '#FDE74C',
  },
  fonts: {
    body: `'Architects Daughter', cursive`,
    heading: 'Helvetica, Arial, sans-serif',
    ink: {
      dark: '#423E37',
      light: '#B7D5D4',
    },
    paper: {
      light: '#B7D5D4',
      dark: '#423E37',
    },
    sizes: {
      small: '12px',
      medium: '16px',
      large: '20px',
      xl: '32px',
      xxl: '48px',
    },
  },
};

export { theme };
