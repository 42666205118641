// <GridContainer>
//   <Header>This is the header</Header>
//   <LeftColumn>This is the left column</LeftColumn>
//   <MainContent>This is the main content area</MainContent>
//   <RightColumn>This is the right column</RightColumn>
//   <Footer>This is the footer</Footer>
// </GridContainer>

import styled from 'styled-components';

const padding = '16px';

interface GridContainerProps {
  height?: string;
}

interface ContentProps {
  $backgroundcolor?: string;
}

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header header header'
    'left main right'
    'footer footer footer';
  height: ${({ height }) => height || '100%'};
`;

export const Header = styled.header`
  padding: ${padding};
  grid-area: header;
  height: 50px;
  background-color: ${({ theme }) => theme.fonts.paper.dark};
  color: ${({ theme }) => theme.fonts.ink.light};
`;

export const Footer = styled.footer`
  padding: ${padding};
  grid-area: footer;
  height: 50px;
  background-color: ${({ theme }) => theme.fonts.paper.dark};
  color: ${({ theme }) => theme.fonts.ink.light};
`;

export const LeftColumn = styled.div`
  padding: ${padding};
  grid-area: left;
  background-color: ${({ theme }) => theme.colors.quaternary};
`;

export const RightColumn = styled.div`
  padding: ${padding};
  grid-area: right;
  background-color: ${({ theme }) => theme.colors.quaternary};
`;

export const MainContent = styled.main<ContentProps>`
  padding: ${padding} 0;
  grid-area: main;
  background-color: ${({ theme, $backgroundcolor }) =>
    $backgroundcolor || theme.colors.quaternary};
`;

export const FullWidthMainContent = styled.main<ContentProps>`
  padding: ${padding} 0;
  grid-area: main;
  grid-column: 1 / 4;
  background-color: ${({ theme, $backgroundcolor }) =>
    $backgroundcolor || theme.colors.quaternary};
  width: 100%;
`;
